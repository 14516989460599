<template>
  <Login />
</template>

<script>
import Login from './components/Login.vue'

export default {
  name: 'App',
  components: {
    Login
  }
}
</script>

<style>
#app {
  font-family: 'Poppins' !important;
  /*text-align: center;*/
  height: 100%;
}
</style>
