import '../node_modules/remixicon/fonts/remixicon.css'
import { createGettext } from '@jshmrtn/vue3-gettext'
import translations from './js/translations.json'
import { createApp } from 'vue'
import App from './App.vue'
import miniToastr from 'mini-toastr'

miniToastr.init()

const gettext = createGettext({
    availableLanguages: {
        en_US: "American English",
        it_IT: "Italiano"
    },
    defaultLanguage: "en_US",
    translations,
    silent: false
});

const myApp = createApp(App)
myApp.use(gettext);

myApp.mount('#app')