<template>
    <div class="col-sm blu-bg" id="iconic-title">
        <div class="container-logo">
            <Image srcVal="logo_home.png" classVal="logo-big" idVal="" titleVal="Iconic" />
            <template v-if="contentPage == 'cardMng'">
                <p class="content-title" v-html="lang.content_card_title" />
            </template>
            <template v-else>
                <p class="content-title" v-html="lang.content_title" />
            </template>
        </div>

        <div class="powered-footer">
            <span>{{ lang.powered_by }}:</span>
            <Image srcVal="logo-retex-short.png" classVal="logo-powered" idVal="" titleVal="Retex S.p.a." />
        </div>
    </div>
</template>

<script>
    import Image from './elements/Image.vue'
    import * as Utility from '../js/utility.js'
    import { useGettext } from '@jshmrtn/vue3-gettext'

    export default {
        name: 'IconicTitle',
        components: {
            Image
        },
        setup() { 
            const language = useGettext();
            localStorage.setItem("lang", language.current);
            return {
               language,
            };
        },
        beforeMount() {
            try{
               if(this.env_content == "card"){ 
                    this.contentPage = "cardMng";
                }
            }catch(Exception){
                this.error_params = true;
            }
        },
        mounted() {  
            this.lang = Utility.getLanguage(this.language);
        },
        data() {
            return {
                lang: "",
                contentPage: "storeMng",
                env_content: process.env.VUE_APP_CONTENT, 
            }
        }
    }
</script>

<style>
    div.col-sm {
        height: 100%;
    }
    div.blu-bg {
        background-color: #00242F;
        box-shadow: inset 0px 0px 5px 2px rgba(0,95,124,1);
    }
    .container-logo{
        position: relative;
        top: calc(40% - 171px);
    }
    .logo-big {
        width: auto;
        height: 250px;
    }
    .content-title {
        margin-top: 50px;
        font-size: 0.9rem;
        color: rgba(225,225,225,.6);
    }
    .powered-footer span {
        color: whitesmoke;
        font-size: 0.8rem;
        margin-bottom: 10px;
    }
    .powered-footer {
        width: calc(100% - 20px);
        position: absolute;
        bottom: 10px;
    }
    .logo-powered {
        width: 20px;
        height: 25px;
        margin: 10px;
    }

    @media screen and (min-width: 956px) and (max-width: 1200px) {
        .container-logo {
            top: calc(40% - 136.5px);
        }
        .logo-big {
            height: 200px;
        }
        .content-title {
            font-size: 0.8rem;
            margin-top: 35px;
        }
    }

    @media screen and (min-width: 770px) and (max-width: 955px) {
        .container-logo {
            top: calc(40% - 111.5px);
        }
        .logo-big {
            height: 150px;
        }
        .content-title {
            font-size: 0.8rem;
            margin-top: 35px;
        }
    }

    @media screen and (max-width: 770px) {
        #iconic-title {
            display: none !important;
        }
    }
</style>
